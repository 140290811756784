import React, { useEffect, useMemo, useRef, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Tabs from "../Components/Tabs";


const Chart = ({spec, model}) => {
    const [activeTab, setActiveTab] = useState(1);
    const chartRef = useRef(null);
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chart.reflow();
        }
    }, [activeTab])

    const columns = [...(model?.dimensions ?? []), ...(model?.metrics ?? [])];

    const format = (value, type, key, compact=true) => {
        if (type === "date") {
            if (key === "year") {
                return value;
            }
            if (key === "month") {
                return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][value - 1];
            }
            if (key === "weekday") {
                return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][value - 1];
            }
            const dateFormatter = new Intl.DateTimeFormat('en-US');
            return dateFormatter.format(new Date(value));
        }
        if (type === "number") {
            const options = {}
            if (compact) {
                options.notation = 'compact';
            }
            const numberFormatter = new Intl.NumberFormat('en-US', options);
            return numberFormatter.format(value);
        }
        if (type === "currency") {
            const options = {
                style: 'currency',
                currency: 'USD',
            }
            if(compact) {
                options.notation = 'compact';
            }
            const currencyFormatter = new Intl.NumberFormat('en-US', options);
            return currencyFormatter.format(value);
        }
        return value;
    }
    const options = useMemo(() => {
        const {viz, data} = spec;
        const title = viz.title || `${viz.y} by ${viz.x}`;
        const xType = columns.find((c) => c.id === viz.x)?.type;
        const yType = columns.find((c) => c.id === viz.y)?.type;
        const sortedData = data.sort((a, b) => a[viz.x] - b[viz.x]);
            
        const selectData = d => {
            if(viz.type === 'pie') {  
                return {y: d[viz.y], name: d[viz.x]}
            }
            return d[viz.y];
        }

        return {
            title: {
                text: title
            },
            chart: {
                type: (viz.type === 'bar' ? 'column' : viz.type) || "line"
            },
            colors: [
                '#8b5cf6',
                '#cf46dc',
                '#fb31ba',
                '#ff2e95',
                '#ff4771',
                '#ff684e',
                '#ff882d',
                '#ffa600',
            ],
            xAxis: {
                categories: sortedData.map(d => d[viz.x]),
                title: {
                    text: columns.find((c) => c.id === viz.x)?.name
                },
                labels: {
                    formatter: function () {
                        let ret = format(this.value, xType, viz.x);
                        console.log(ret);
                        return ret
                    }
                }
            },
            series: [{
                data: sortedData.map(selectData),
                name: columns.find((c) => c.id === viz.y)?.name
            }],
            yAxis: {
                title: {
                    text: columns.find((c) => c.id === viz.y)?.name
                },
                labels: {
                    formatter: function () {
                        return format(this.value, yType, viz.y);
                    }
                }
            },
            tooltip:{
                formatter: function() {
                    return ` ${format(this.x, xType, viz.x, false)}: <b>${format(this.y, yType, viz.y, false)}</b>`;
                }
            }
        }
    }, [spec, model])

    const cellFormatter = (value, key) => {
        const fmtType = columns.find((c) => c.id === key)?.type;
        return format(value, fmtType, key, false);
    }
    console.log(options)

    return (
        <div className="card mt-2 w-full">
            <Tabs tabs={[{id: 1, name: "Chart"}, {id: 2, name: "Data"}]} activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="mt-3 h-80">
                {activeTab === 1 && <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />}
                {activeTab === 2 && (
                    <table className="table-auto w-full overflow-hidden">
                        <thead>
                            <tr className="text-center">
                                {Object.keys(spec.data[0]).map(key => <th key={key} className="border px-4 py-2">{columns.find((c) => c.id === key)?.name ?? key}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {spec.data.map((row, i) => (
                                <tr key={i} className="text-center">
                                    {Object.keys(row).map(key => <td key={key} className="border px-4 py-2">{cellFormatter(row[key], key)}</td>)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

export default Chart;