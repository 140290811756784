import { getToken, refreshToken } from "./auth";

let API_URL = 'https://michaellepine.ca';
if(process.env.NODE_ENV === 'development'){
    API_URL = 'https://upgraded-space-couscous-4qpqv6wj9xhxwp-8000.app.github.dev/';
}

const apiFetch = async (path, options) => {
    const isAuthUrl = path.startsWith("/auth/");
    if (isAuthUrl === false) {
        const token = getToken()?.access;
        if (token) {
            options = options || {};
            options.headers = {
                "Content-Type": "application/json",
                ...options.headers,
                "Authorization": `JWT ${token}`,
            };
        }
    }
    const url = `${API_URL}${path}`;
    let response = {};
    const retryCount = options?.retryCount || 0;
    try {
        response = await fetch(url, options);
    } catch (error) {
        response.ok = false;
        response.statusText = error.message;
        console.error('fetch failed', error);
        if (retryCount > 3) {
            throw new Error("Too many retries");
        }
        await new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
        return apiFetch(path, { ...options, retryCount: retryCount + 1 });
    }
    if (response.ok) {
        return response.json();
    } else {
        if (response.status === 401) {
            if(url.includes('/auth/jwt/refresh/')) {
                throw new Error("Failed to refresh token");
            }
            if (retryCount > 3) {
                throw new Error("Too many retries");
            }
            try {
                await refreshToken();
            } catch (error) {
                throw new Error("Failed to refresh token");
            }
            if (url.includes('/auth/jwt/verify/')) {
                // Let the verify code path refresh since it needs the new token
                throw new Error(response.statusText);
            }
            return apiFetch(path, { ...options, retryCount: retryCount + 1 });
        }
        throw new Error(response.statusText);
    }
}

export default apiFetch;

export {API_URL, apiFetch};
