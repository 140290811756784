import React, { useState } from "react";
import { NavLink, Navigate, Outlet, redirect } from "react-router-dom";
import { HiChat, HiHome, HiLogout, HiShoppingCart, HiTable, HiUser } from "react-icons/hi";
import { FaMagnet } from "react-icons/fa";
import apiFetch, { API_URL } from "../api";
import useAuthentication from "../hooks/useAuthentication";
import { useQuery } from "react-query";
import { removeToken } from "../auth";


const SidebarLink = ({ to, children, icon }) => {
    return (
        <li>
            <NavLink to={to} className={({ isActive }) => `${isActive ? "bg-gray-200 text-primary-600" : "text-grey-950"} flex group items-center p-2 font-bold rounded-lg dark:text-white hover:bg-gray-300 dark:hover:bg-gray-700 group`}>
                {({ isActive }) => (
                    <>
                        {icon && (
                            <span className={`${isActive ? "text-primary-700" : "text-gray-500"} group-hover:text-primary-600 text-2xl p-1`}>
                                {icon}
                            </span>
                        )}
                        <span className="ms-3 w-full group-hover:text-primary-600">{children}</span>
                    </>
                )
                }
            </NavLink>
        </li >
    )
};

const Layout = () => {
    const [show, setShow] = useState(false);
    const { isAuthenticated, clearAuthentication } = useAuthentication();
    const { data: userData } = useQuery(['users', 'me'], async () => await apiFetch(`/api/users/me/`), { enabled: !!isAuthenticated.data });
    if(isAuthenticated.isLoading) {
        return (
            <div>Loading...</div>
        )
    }
    if(!isAuthenticated.data) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="">
            <button onClick={() => setShow(!show)} aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>
            <aside id="default-sidebar" className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${show ? "" : "-translate-x-full"} sm:translate-x-0`} aria-label="Sidebar">
                <div className="flex h-full flex-col bg-white dark:bg-gray-800 px-3 border border-r-1 border-gray-300">
                    <div className="h-16 py-4 bg-white flex items-center justify-center p-2 text-xl text-primary-600">
                        <span className="font-3xl"><FaMagnet /></span> <span className="text-gray-800 ms-3">FridgeMagnet</span>
                    </div>
                    <div className="bg-gray-300 h-px">
                    </div>
                    <div className="flex-grow py-4 overflow-y-auto">
                        <ul className="space-y-2 font-medium">
                            <SidebarLink to="/" icon={<HiHome />}>Home</SidebarLink>
                            <SidebarLink to="/chats" icon={<HiChat />}>Chats</SidebarLink>
                            <SidebarLink to="/canvas" icon={<HiTable />}>Explorer</SidebarLink>
                            <SidebarLink to="/deals" icon={<HiShoppingCart />}>Deals</SidebarLink>
                        </ul>
                    </div>
                    <div className="bg-gray-300 h-px">
                    </div>
                    <div className="h-16 py-4">
                        <ul className="space-y-2 font-medium">
                            <SidebarLink to="/users" icon={<HiUser />}>
                                <div className="flex w-full">
                                    <span className="flex-grow">
                                        {userData?.first_name}
                                    </span>
                                    <span className="ml-auto hover:bg-primary-600 rounded-sm p-1 cursor-pointer" onClick={
                                        () => {
                                            removeToken();
                                            clearAuthentication();
                                            return redirect("/login");
                                        }
                                    }>
                                        <HiLogout />
                                    </span>
                                </div>
                            </SidebarLink>
                        </ul>
                    </div>
                </div>
            </aside>
            <div className="p-4 sm:ml-64 min-h-screen bg-white" onClick={() => {
                if (show) setShow(false);
            }} >

                <Outlet />
                {(process.env.NODE_ENV === "development") && (
                    < div className="bg-primary-700 text-white fixed bottom-0 right-0 p-1">
                        {API_URL}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Layout;
